export const LangData = {
  tr: {
    component: {
      bottomInfo: [
        {
          icon: 'profile',
          info: [
            'Hafta içi mesai saatleri içinde:',
            '+90 212 501 93 13',
            'Numaralı Telefondan Ulaşabilirsiniz`',
          ],
        },
        {
          icon: 'card',
          info: ['Güvenli Ödeme Sistemi'],
        },
        {
          icon: 'gift',
          info: ['Fratelli Diamanti Özel Hediye Kutusunda'],
        },
        {
          icon: 'parfum',
          info: ['Özel Tester Numune Parfüm Hediye'],
        },
      ],
      newsletter: {
        title: 'Bülten',
        subTitle: 'BİZDEN HABERDAR OLMAK İÇİN KAYDOLUN',
        success: 'Bültene başarıyla kayıt oldunuz.',
      },
      footer: [
        {
          title: 'Fratelli Diamanti',
          links: [
            {
              title: 'Shades Of Grey',
              path: '/product/shades-of-grey',
            },
            {
              title: 'Ceyhan Elmas',
              path: '/ceyhan-elmas',
            },
          ],
        },
        {
          title: 'Mağazalar',
          links: [
            {
              title: 'Milano',
              path: '/store',
            },
            {
              title: 'Stuttgart',
              path: '/store',
            },

            {
              title: 'Dubai',
              path: '/store',
            },
            {
              title: 'Istanbul',
              path: '/store',
            },
          ],
        },
        {
          title: 'Contact',
          links: [
            {
              title: 'Bize Ulaşın',
              path: '/contact',
            },
            {
              path: '/product/shades-of-grey',
            },
          ],
        },
        {
          title: 'Legal Statement',
          links: [
            {
              title: 'Kişisel Verilerin Korunması Kanunu',
              path: '/kvkk',
            },
            {
              title: 'Mesafeli Satış Sözleşmesi',
              path: '/mesafeli-satis-sozlesmesi',
            },
            {
              title: 'İptal İade Koşulları',
              path: '/iptal-iade-kosullari',
            },
          ],
        },
      ],
      header: [
        {
          title: 'SHADES OF GREY',
          path: '/shades-of-grey',
        },
        {
          title: 'MILANO',
          path: '/discover/milano',
        },
        {
          title: 'RED ROUGE',
          path: '/discover/red-rouge',
        },
        {
          title: 'AMBRE ROYALE',
          path: '/discover/ambreroyale',
        },
        {
          title: 'AL CIELO',
          path: '/discover/alcielo',
        },
        {
          title: 'KOLEKSİYON',
          path: '/koleksiyon',
        },
        {
          title: 'PARFÜM',
          path: '/parfum',
        },
        {
          title: 'CEYHAN ELMAS',
          path: '/ceyhan-elmas',
        },
      ],
      signature: {
        text: {
          desktop: [
            `Kaliteli, şık, karakter kokan parfümler`,
            `oluşturmaya odaklandım.`,
            `Çoşkumu ve tutkumu besleyen parfümler`,
            `yaratmaya çalışırken bazen bu uçsuz bucaksiz`,
            `dünyada kayboldum.`,
          ],
          mobile: [
            `Kaliteli, şık, karakter kokan parfümler oluşturmaya odaklandım.
            Çoşkumu ve tutkumu besleyen parfümler yaratmaya çalışırken bazen bu
            uçsuz bucaksiz dünyada kayboldum.`,
          ],
        },
      },
      menuData: [
        {
          title: 'Shades Of Grey',
          type: 'link',
          link: '/shades-of-grey',
        },
        {
          title: 'Ceyhan Elmas',
          type: 'link',
          link: '/ceyhan-elmas',
        },
        {
          title: 'Koleksiyon',
          type: 'link',
          link: '/koleksiyon',
        },
        {
          title: 'Parfüm',
          type: 'link',
          link: '/parfum',
        },
        {
          title: 'Mağazalar',
          type: 'link',
          link: '/store',
        },
        {
          title: 'Bize Ulaşın',
          type: 'link',
          link: '/contact',
        },        
        {
          title: 'Kişisel Verilerin Korunması Kanunu',
          type: 'link',
          link: '/kvkk',
        },
        {
          title: 'Kalite Çevre ve GMP Politikamız',
          type: 'link',
          link: '',
        },
        {
          title: 'İptal ve İade Koşulları',
          type: 'link',
          link: '/iptal-iade-kosullari',
        },
        {
          title: 'Üye Ol',
          type: 'link',
          link: '/login',
        },
        {
          title: 'Üye Girişi',
          type: 'link',
          link: '/register',
        },
        {
          title: 'Sepetim',
          type: 'link',
          link: '/cart?section=product',
        },
      ],
      breadCrumb: {
        home: 'ANASAYFA',
        signOut: 'ÇIKIŞ YAP',
        cart: 'ALIŞVERİŞ SEPETİM',
        customerInfo: 'MÜŞTERİ BİLGİLERİ',
        paymentSelection: 'ÖDEME SEÇENEKLERİ',
        parfum: 'PARFÜM',
      },
    },
    home: {
      head: {
        title: [
          'Duygular tutkuyla saran bastan sikaric ve sehvetli bir',
          'yolculukta size inamilmaz bir his yaratıyor.',
        ],
        discover: 'KEŞFET',
        scroll: 'SCROLL TO DISCOVER',
        buttonText: 'Satın Al',
      },
      product: {
        title: ['Sizin için', 'seçtiklerimiz'],
        paragraph: [
          'Sizi hayatın ışıltılı anlarından zevk almaya ve gittiginiz her yerde ',
          'büyülü bir iz burakacaginz kokuyla tanıştırıyoruz.',
        ],
      },
      grey: [
        'The fragrances that suit you best thanks the',
        'fragrance finder this allows developed by Fratelli',
        'Diamanti allows you to to determine which',
        'fragrances. that suit you best thanks the fragrance',
        'finder this allows developed',
      ],
      feed: [
        {
          title: 'Ceyhan Elmas',
          path: '/ceyhan-elmas',
          button: 'KEŞFET',
        },
        {
          title: 'Shades Of Grey',
          path: '/discover/shades-of-grey',
          button: 'KEŞFET',
        },
      ],
    },
    ceyhan: {
      head: {
        title: {
          desktop: [
            'Fratelli Diamanti aslında birden fazla ilkenin',
            've hayalin uzun yıllar demlendikten sonra',
            'doğmuş haliydi benim için…',
          ],
          mobile: [
            'Fratelli Diamanti aslında birden fazla ilkenin',
            've hayalin uzun yıllar demlendikten sonra',
            'doğmuş haliydi benim için…',
          ],
        },
        info: {
          text: `2007 yılında hobi olarak başlayan hikayem ‘ bir tutkunun peşinden gitmek olarak tanımladığım yolculuğa dönüştü. Hayatımın bir kısmı duyuları orataya çıkaran kokulara duyduğum ilgi ve merakla birlikte gittiğim ülkelerde ki  parfüm dünyasının  anlam ve tasarım yönünü hissetmek için yaptığım araştırmalarla geçti.`,
          paragraph: [
            `Zamanla çalışmalarımı büyüterek parfüm sektöründe bir tasarımcı olarak çalıştığım süreçlerde edindiğim bilgiler, katıldığım seminerler ve aldığım eğitimler bu yöndeki gelişimime büyük bir ivme kazandırdı. Zorlu olan bu yolculuktan aldığım heyecan ve tutku ise kısa süre içinde beni zengin koku kültürüne sahip ilhamın kalbi olan İtalya’ya sürükledi.`,
            `Ruhuma dokunan şehir bana bazen hayallerimi hatırlatarak, gerçekten neyi yapmak istediğimi bir kez daha büyük bir içtenlikle anlatmıştı. 
            Bu süreçte zamanla neyi ve neden istediğimi biliyor olmamı farketmemi ve  kısa süre içinde kendi markamı kurmamı sağladı. Fratelli Diamanti adı altındaki markanın ilk temelini 2013 yılında atmış oldum. Bugün geldiğim nokta ve hayatımda verdiğim bütün önemli kararlarda ve kariyerimle birlikte markamın şekillenmesinde her zaman kendi iç sesimi, sezgilerimi ve vizyonumu takip ettim. `,
            `Bugün beni buraya getiren her şey beni ben yaptı.  
            Fratelli Diamanti aslında birden fazla ilkenin ve hayalin uzun yıllar demlendikten sonra doğmuş haliydi benim için. 
            Ne kadar kazandığıma değil ne kadar iyi yaptığıma baktım hep…`,
          ],
        },
        bottom: {
          title: '“Fratelli Diamanti” Bir parfümden çok daha fazlasi…',
          paragraph: [
            `Bazen sadece güzel kokmak yeterli gelmez. `,
            `Bir büyüye kapılıp gitmek ya da duygularımızın 
            bize kendimizi özel ve daha farklı hissettirmesini isteriz.`,
            `Etrafımızdaki duyguları anlamlandırma sürecinde  duyulara verilen tepkiler benim için çok önemli bir yere sahiptir. Bugüne kadar tasarladığım her parfüm benim için kusursuz ve benzersiz bir konumda onlar hayatımın farklı anlarının bir parçası. Parfümlerimde her şey birbirine bağlıdır tıpkı ruhlar ve kokular gibi…`,
          ],
        },
      },
    },
    storeList: {
      title: 'Mağazalarımız',
      selectCity: 'ŞEHİR SEÇİNİZ',
      showStore: 'MAĞAZALARI GÖSTER',
      location: 'KONUMUMA GÖRE BUL',
      openToday: 'OPEN TODAY :',
    },
    shadesOfGrey: {
      headCap: {
        one:'Siyahla Beyazın',
        two:'Hikayesi',
        three:'Siyahla Beyazın Hikayesi',
        four:' Shades of Grey’de',
        five:'Buluştu',
      },
      buy: {
        title: 'Satın Al',
        path: '/product/shades-of-grey',
      },
      halfTitle: {
        desktop: [
          `Shades of Grey ile`,
          `iz bırakırken hayatına girecek olan`,
          `magazine yer aç…`,
        ],
        mobile: [
          `Shades of Grey ile`,
          `iz bırakırken`,
          `hayatına girecek olan`,
          `magazine yer aç…`,
        ],
      },
      crystal: {
        title: [`everyone is`, `after them`],
        subTitle: [`but the only thıng`, `they’re after`],
      },
      textPart: {
        1: [
          `Arzuların gölgelerle dansı…`,
          `Tutkunun ve arzunun adı gri amber ile gölgelerin dansını her an
            hissedeceksin.`,
          `Tenindeki sıcaklığa sarılan kokunla, adım attığın her yerde iz
            bırakacaksın.`,
        ],
        2: [
          `Lüksün imzası olan parfüm içeriklerinden gri amberin gölgelerle
            dansını koklayacaksınız.`,
          `Işıltılı misk ’in gölgesinde ahenkli sandal ve sedir kokuları
            birbirine sarılırken zihninizde iz bırakan bir parfüm canlanıyor.
            Adaçayı, pembe biber ve lavanta parfümünüzün ışıltısına zarafet
            katıyor.`,
          `Bir kalıba, bir kategoriye girmeye itiraz eden tüm kokuların güç
            birliğine ve baş kaldırısına şahit oluyorsunuz bu şişenin içinde.
            Erkek ya da kadın değil, genç ya da olgun değil, dinamik ya da silik
            değil, siyah yada beyaz hiç değil.`,
          `Grinin gölgelerinde gezinen, tenlerin gölgesinde bir başrol oyuncusu
            Shades of Grey…`,
        ],
      },
      product: {
        title: [`Shades of Grey ile`, `Randevun Var`],
        subTitle: [`Sabırlı ol,`, `ruh halinin degişmesine`, `çok az kaldı`],
        buy: {
          title: 'SATIN AL',
          path: '/product/shades-of-grey',
        },
      },
    },
    login: {
      title: 'Giriş Yap',
      userName: 'KULLANICI ADI',
      password: 'ŞİFRE',
      errorTitle: 'Giriş bilgileri hatalıdır',
      error: {
        username: 'Doğru bir kullanıcı adı giriniz.',
        password: 'Şifre en az 8 karakter içermelidir.',
        required: 'Zorunlu alan',
      },
      login: 'GİRİŞ YAP',
      signOut: 'ÇIKIŞ YAP',
      rememberMe: 'BENİ HATIRLA',
      forgot: [`ŞİFREMİ`, 'UNUTTUM?'],
      register: 'HESAP OLUŞTUR',
    },
    register: {
      title: 'Hesap Oluştur',
      name: 'İSİM',
      surname: 'SOYİSİM',
      userName: 'KULLANICI ADI',
      email: 'EMAIL',
      phone: 'TELEFON',
      password: 'ŞİFRE',
      passwordAgain: 'ŞİFRE TEKRAR',
      errorTitle: 'Kayıt olurken hata oluştu',
      error: {
        username: 'Kısa kullanıcı adı.',
        password: 'Şifre en az 8 karakter içermelidir.',
        passwordAgain: 'Önceki şifre ile aynı olmalıdır',
        phone: 'Doğru bir telefon numarası giriniz.',
        required: 'Zorunlu alan',
        surname: 'Kısa soyisim.',
        name: 'Kısa isim.',
        email: 'Doğru bir email adresini giriniz.',
      },
      register: 'KAYDOL',
      login: 'HESABIN VAR MI? GİRİŞ YAP',
    },
    product: {
      info: {
        title: '65 ML / ExDP',
        addToCart: 'SEPETE EKLE',
        alreadyAdded: '+ SEPETE EKLENDİ',
        paragraph: [
          `Aynı parfümle farklı sen…`,
          `Asil karmaşık ve sofistike parfüm içeriklerinden gri amberin
                ışıltısını ve gölgelerle dansını koklayacaksınız.`,
          `Adaçayı pembe biber ve lavanta, parfümünüzün ışıltısına zarafet
                katıyor. Şehvetle birbirine karışan içeriklerin ahenkle dans eden
                harmonisi parıldıyor teninizde. Değerli parfüm sanatçısı Julia
                Rodriguez ile birlikte hazırlanarak hayat bulan sihirli iksirimiz
                sizlerle tam uyum sağlamak üzere bir araya geldi.`,
          `Kişiye özel olarak koku yönünü değiştirebilen doğal ve nadide
                içerikler teninizi terk etmemeye kararlı bir yapıya sahip.`,
        ],
        button: [`ILHAM`, 'KOKU NOTALARI', 'DIAMOND COLLECTION'],
        factory: 'Koku Notaları',
      },

      inspiration: {
        title: 'Ilham',
        paragraph: [
          `Gri Amber tabanında kalıcılığı lüks kokusu ve  sert yapısını misk yardımıyla bir adım öteye taşıdık. O’nu koklarken buğulu gizemli ve asil bir tat alıyorsunuz.`,
          `Notalar arasındaki  geçişler bağlantı noktaları o kadar buğulu ki ancak gölgesel bir hareket ile tanımlanabilirdi. Değerli  dostumuz yol arkadaşımız Julia ile sofistike ve baştan çıkarıcı büyülü bir karmaşa yaratmaya  çalıştık.`,
          `O şimdi gerçekten tam istediğimiz gibi kokan bir parfüm oldu.`,
          `Bir kokunun yaşatacağı hissi hayal edip bunu tam olarak şişeye yansıtabilmek gerçekten sanatsal bir yolculuk…`,
        ],
      },
      service: {
        title: [`Fratelli Diamanti`, `Exclusive Online Services`],
        data: [
          {
            title: 'ZARİF SUNUM',
            text: `Bir Fratelli Diamanti hediye kutusunda güzel bir şekilde sunulacak
                olan satın aldığınız ürünün şık sunumunun keyfini çıkarın ve
                kişisel bir mesaj ekleyin.`,
          },
          {
            title: 'SAMPLES',
            text: `Her satın alımda 1 ücretsiz numune alın. Parfüm koleksiyonumuzdan
            seçim yapın.`,
          },
          {
            title: 'ÖDEME',
            text: `Çevrimiçi satın alma işleminizin ödemesi güvenlidir.`,
          },
          {
            title: 'MÜŞTERİ HİZMETLERİ',
            text: `Mesai saatleri içerisinde mail adresinden müşteri danışmanlarımıza
            ulaşabilirsiniz.`,
          },
        ],
      },
    },
    collection: {
      title: ['Fratelli Diamanti', 'Koleksiyonlar'],
    },
    parfum: {
      title: ['Fratelli Diamanti', 'Parfüm'],
    },
    basket: {
      basketCaption: 'Alışveriş Sepetim',
      orderSummary: 'Sipariş Özeti',
      subTotal: 'TOPLAM TUTAR',
      discount: 'ETİKET İNDİRİMİ',
      totalAmount: 'ÖDENECEK TUTAR',
      buy: 'SATIN AL',
      discountCoupon: 'İNDİRİM KUPONU EKLE',
      piece: 'ADET',
      delete: 'SİL',
      deliveryAddress: 'Teslimat Adresi',
      invoiceAddress: 'Fatura Adresi',
      addressButton: 'YENİ ADRES EKLE',
      sameAdress: 'FATURA ADRESİM TESLİMAT ADRESİM İLE AYNI',
      edit: 'DÜZENLE',
      title: 'BAŞILIK',
      city: 'ŞEHİR SEÇİNİZ',
      province: 'İLÇE',
      address: 'ADRES',
      addAddress: 'ADRES EKLE',
      editAddress: 'ADRES DÜZENLE',
      creditCard: 'KREDİ / BANKA KARTI',
      creditNumber: 'KART NUMARASI',
      creditName: 'KART ÜZERİNDEKİ AD SOYAD',
      month: 'AY',
      year: 'YIL',
      cvc: 'CVC',
    },
  },
  en: {
    component: {
      bottomInfo: [
        {
          icon: 'profile',
          info: [
            'During weekdays, within working hours:',
            'You can reach us at',
            '+90 212 501 93 13',
          ],
        },
        {
          icon: 'card',
          info: ['Safe Payment System'],
        },
        {
          icon: 'gift',
          info: [`Fratelli Diamanti In It's Special Gift Box`],
        },
        {
          icon: 'parfum',
          info: ['Free Fragrance Tester Sample'],
        },
      ],
      newsletter: {
        title: 'News',
        subTitle: 'Sign up to stay informed about us',
        success: 'Bültene başarıyla kayıt oldunuz.',
      },
      footer: [
        {
          title: 'Fratelli Diamanti',
          links: [
            {
              title: 'Shades Of Grey',
              path: '/product/shades-of-grey',
            },
            {
              title: 'Ceyhan Elmas',
              path: '/ceyhan-elmas',
            },
          ],
        },
        {
          title: 'Stores',
          links: [
            {
              title: 'Milano',
              path: '/store',
            },
            {
              title: 'Stuttgart',
              path: '/store',
            },

            {
              title: 'Dubai',
              path: '/store',
            },
            {
              title: 'Istanbul',
              path: '/store',
            },
          ],
        },
        {
          title: 'Contact',
          links: [
            {
              title: 'Contact Us',
              path: '/contact',
            },
            {
              path: '/product/shades-of-grey',
            },
          ],
        },
        {
          title: 'Legal Statement',
          links: [
            {
              title: 'Personal Data Protection Law',
              path: '/kvkk',
            },
            {
              title: 'Distance Sales Agreement',
              path: '/mesafeli-satis-sozlesmesi',
            },
            {
              title: 'Cancellation and Refund Conditions',
              path: '/iptal-iade-kosullari',
            },
          ],
        },
      ],
      header: [
        {
          title: 'SHADES OF GREY',
          path: '/shades-of-grey',
        },

        {
          title: 'MILANO',
          path: '/discover/milano',
        },
        {
          title: 'RED ROUGE',
          path: '/discover/red-rouge',
        },
        
        {
          title: 'COLLECTION',
          path: '/koleksiyon',
        },
        {
          title: 'PERFUME',
          path: '/parfum',
        },
        {
          title: 'CEYHAN ELMAS',
          path: '/ceyhan-elmas',
        },
      ],
      signature: {
        text: {
          desktop: [
            `I focused on creating high-quality, elegant,`,
            `and character-filled fragrances.`,
            `While attempting to craft perfumes`,
            `that nourish my enthusiasm and passion,`,
            `I found myself lost in this vast world.`,
          ],
          mobile: [
            `I focused on creating high-quality, elegant, and character-filled fragrances.
            While attempting to craft perfumes that nourish my enthusiasm and passion, I found myself lost in this vast world.`,
          ],
        },
      },
      menuData: [
        {
          title: 'Shades Of Grey',
          type: 'link',
          link: '/shades-of-grey',
        },
        {
          title: 'Ceyhan Elmas',
          type: 'link',
          link: '/ceyhan-elmas',
        },
        {
          title: 'Collection',
          type: 'link',
          link: '/koleksiyon',
        },
        {
          title: 'Perfume',
          type: 'link',
          link: '/parfum',
        },
        {
          title: 'Stores',
          type: 'link',
          link: '/store',
        },
        {
          title: 'Contact Us',
          type: 'link',
          link: '/contact',
        },

        {
          title: 'Personal Data Protection Law',
          type: 'link',
          link: '/kvkk',
        },
        {
          title: 'Distance Sales Agreement',
          type: 'link',
          link: '',
        },
        {
          title: 'Cancellation and Refund Conditions',
          type: 'link',
          link: '/iptal-iade-kosullari',
        },
        {
          title: 'Create An Account',
          type: 'link',
          link: '/login',
        },
        {
          title: 'Log In',
          type: 'link',
          link: '/register',
        },
        {
          title: 'Basket',
          type: 'link',
          link: '/cart?section=product',
        },
      ],
      breadCrumb: {
        home: 'HOME',
        signOut: 'LOG OUT',
        cart: 'CART',
        customerInfo: 'CUSTOMER INFO',
        paymentSelection: 'PAYMENT OPTIONS',
        parfum: 'PERFUME',
      },
    },
    home: {
      head: {
        title: [
          'In a captivating and sensual journey that passionately envelops emotions,',
          ' it creates an incredible sensation for you.',
        ],
        discover: 'DISCOVER',
        scroll: 'SCROLL TO DISCOVER',
        buttonText: 'Buy Now',
      },
      product: {
        title: ['Specially', 'Chosen for You'],
        paragraph: [
          'We introduce you to a fragrance that allows you to enjoy the glittering ',
          'moments of life and leave a magical trace wherever you go.',
        ],
      },
      grey: [
        'The fragrances that suit you best thanks the',
        'fragrance finder this allows developed by Fratelli',
        'Diamanti allows you to to determine which',
        'fragrances. that suit you best thanks the fragrance',
        'finder this allows developed',
      ],
      feed: [
        {
          title: 'Ceyhan Elmas',
          path: '/ceyhan-elmas',
          button: 'DISCOVER',
        },
        {
          title: 'Shades Of Grey',
          path: '/discover/shades-of-grey',
          button: 'DISCOVER',
        },
      ],
    },
    ceyhan: {
      head: {
        title: {
          desktop: [
            'Fratelli Diamanti, for me, was the culmination of',
            'multiple principles and dreams that had been',
            'brewing for many years...',
          ],
          mobile: [
            'Fratelli Diamanti, for me, was the culmination of',
            'multiple principles and dreams that had been',
            'brewing for many years...',
          ],
        },
        info: {
          text: `What began as a hobby in 2007 evolved into a journey I defined as following the pursuit of a passion. A significant
          part of my life was spent researching the meaning and design aspects of the world of perfumes in the countries I visited, driven by my interest and curiosity in scents that evoke the senses.`,
          paragraph: [
            `Over time, expanding my endeavors and working as a designer in the perfume industry, the knowledge gained from my experiences,
            seminars attended, and training received significantly accelerated my development in this direction. The excitement and passion I derived from this challenging journey quickly led me to Italy, the heart of inspiration with a rich fragrance culture. The city
            that touched my soul sometimes reminded me of my dreams, earnestly emphasizing what I truly wanted to do. Through this process, I gradually became aware of what and why I desired, eventually enabling me to establish my own brand. I laid the initial foundation
            for the Fratelli Diamanti brand in 2013. `,

            `In all the important decisions in my life and the shaping of my career and brand, I have always followed my inner voice,
            instincts, and vision. Everything that brought me here made me who I am. `,

            `Fratelli Diamanti, for me, was the culmination of multiple principles and dreams that had been brewing for many years.
            I always focused on how well I did things, not just how much I gained...`,
          ],
        },
        bottom: {
          title: '"Fratelli Diamanti" is much more than a perfume...',
          paragraph: [
            `Sometimes, merely smelling good is not enough.`,
            `We may want to be captivated by a spell or have our emotions make usfeel special and different.`,

            `In the process of interpreting the emotions around us, the reactions given to the senses hold great importance for
             me. Every perfume I have designed so far holds a perfect and unique position, much like pieces of different moments in my life. In my perfumes, everything is interconnected, just like souls and scents.`,
          ],
        },
      },
    },
    storeList: {
      title: 'Our Stores',
      selectCity: 'Select City',
      showStore: 'Show Stores',
      location: 'Find by Location',
      openToday: 'OPEN TODAY :',
    },
    shadesOfGrey: {
      headCap: {
        
        one:'Story Of',
        two:'Black And White',
        three:'Story Of Black And White',
        four:'Meets',
        five:'Shades Of Grey',
      },
      buy: {
        title: 'Buy Now',
        path: '/product/shades-of-grey',
      },
      halfTitle: {
        desktop: [
          `While leaving a mark with Shades of Grey`,
          `make room for the magazine`,
          `that will enter your life.`,
        ],
        mobile: [
          `While leaving a mark with Shades of Grey`,
          `make room for the magazine`,
          `that will enter your life.`,
        ],
      },
      crystal: {
        title: [`everyone is`, `after them`],
        subTitle: [`but the only thıng`, `they’re after`],
      },
      textPart: {
        1: [
          `The dance of desires with shadows...`,
          `With the name of passion and desire, grey amber, you will feel the dance of shadows in every moment.`,
          `With the scent embracing the warmth of your skin, you will leave a trace wherever you step.`,
        ],
        2: [
          `You will inhale the grey amber dancing with shadows, a signature perfume ingredient of luxury.`,
          `As harmonious scents of sparkling musk, cedar, and sandalwood intertwine in the shimmering shadow, a perfume that leaves its mark on your mind comes to life.
          Sage, pink pepper, and lavender add elegance to the radiance of your fragrance.`,
          `Witness the unity and rebellion of all scents that defy fitting into a mold or category within this bottle.
          Neither male nor female, young nor mature, dynamic nor subdued, black nor white, this fragrance rebels against conventional norms.`,
          `A leading actor, Shades of Grey, lurking in the shades of grey, gliding through the shadows of skin...`,
        ],
      },
      product: {
        title: [`You Have a Date With`, `Shades of Grey`],
        subTitle: [`Be patient,`, `your mood is aboutatichange`, `very soon.`],
        buy: {
          title: 'BUY NOW',
          path: '/product/shades-of-grey',
        },
      },
    },
    login: {
      title: 'Login',
      userName: 'USERNAME',
      password: 'PASSWORD',
      errorTitle: 'Login details are incorrect.',
      error: {
        username: 'Doğru bir kullanıcı adı giriniz.',
        password: 'Password needs to contain at least 8 characters.',
        required: 'Required',
      },
      login: 'LOGIN',
      signOut: 'LOG OUT',
      rememberMe: 'REMEMBER ME',
      forgot: [`I FORGOT`, 'MY PASSWORD?'],
      register: 'CREATE AN ACCOUNT',
    },
    register: {
      title: 'Register',
      name: 'NAME',
      surname: 'SURNAME',
      userName: 'USERNAME',
      email: 'EMAIL',
      phone: 'PHONE',
      password: 'PASSWORD',
      passwordAgain: 'PASSWORD AGAIN',
      errorTitle: 'Register details are incorrect.',
      error: {
        username: 'Kısa kullanıcı adı.',
        password: 'Şifre en az 8 karakter içermelidir.',
        passwordAgain: 'Önceki şifre ile aynı olmalıdır',
        phone: 'Doğru bir telefon numarası giriniz.',
        required: 'Required',
        surname: 'Kısa soyisim.',
        name: 'Kısa isim.',
        email: 'Doğru bir email adresini giriniz.',
      },
      register: 'REGISTER',
      login: 'DO YOU HAVE AN ACCOUNT? LOGIN',
    },
    product: {
      info: {
        title: '65 ML / ExDP',
        addToCart: 'ADD TO CART',
        alreadyAdded: '+ ADDED TO CART',
        paragraph: [
          `Same perfume, different you...`,
          `You will inhale the radiance and dance with shadows of grey amber, derived from noble, intricate and sophisticated perfume ingredients`,
          `Sage, pink pepper, and lavender add elegance to the radiance of your fragrance. The harmonious dance of sensually blended ingredients sparkles on your skin with grace. Our magical elixir, crafted in collaboration with the esteemed perfume artist Julia Rodriguez, has come together to perfectly harmonize with you.`,
          `Formulated to linger on your skin, the unique and rare ingredients have the ability to tailor the scent direction according to your individuality. A steadfast creation, determined not to leave your skin.`,
        ],
        button: [`INSPIRATION`, 'NOTES', 'DIAMOND COLLECTION'],
        factory: 'Fragrance Notes',
      },
      inspiration: {
        title: 'Inspiration',
        paragraph: [
          `We elevated the longevity, luxurious fragrance, and robust structure of Grey Amber to a new level with the assistance of musk. When you smell it, you experience a hazy, mysterious, and noble flavor.`,
          `The transitions between notes are so elusive that they could only be defined through a shadowy movement. With our valued companion Julia, we attempted to create a sophisticated and enchanting magical chaos.`,
          `Now, it has truly become a perfume that smells exactly as we desired. Imagining the emotions a fragrance can evoke and accurately reflecting it in a bottle is truly an artistic journey…`,
        ],
      },
      service: {
        title: [`Fratelli Diamanti`, `Exclusive Online Services`],
        data: [
          {
            title: 'ELEGANT PRESENTATION',
            text: `Enjoy the stylish presentation of your purchased item, beautifully presented 
            in a Fratelli Diamanti gift box, and add a personal message.`,
          },
          {
            title: 'SAMPLES',
            text: `Get one free sample with every purchase. 
            Make your choice rom ourtracrance collecion.`,
          },
          {
            title: 'PAYMENT',
            text: `Your online shopping from us is completly safe.`,
          },
          {
            title: 'CUSTOMER SERVİCES',
            text: `within working hours. you can contact us with phone or e-mail.`,
          },
        ],
      },
    },
    collection: {
      title: ['Fratelli Diamanti', 'Collections'],
    },
    parfum: {
      title: ['Fratelli Diamanti', 'Perfume'],
    },
    basket: {
      basketCaption: 'Shopping Cart',
      orderSummary: 'Summary',
      subTotal: 'SUBTOTAL',
      discount: 'DISCOUNT',
      totalAmount: 'TOTAL AMOUNT',
      buy: 'BUY',
      discountCoupon: 'ADD DISCOUNT COUPON',
      piece: 'PIECE',
      delete: 'DELETE',
      edit: 'EDİT',
      deliveryAddress: 'Delivery Address',
      invoiceAddress: 'Invoice Address',
      addressButton: 'ADD NEW ADDRESS',
      sameAddress: 'THE SAME IN BOTH ADDRESSES',
      title: 'TITLE',
      city: 'SELECT CITY',
      province: 'COUNTRY',
      address: 'ADDRESS',
      addAddress: 'ADD ADDRESS',
      editAddress: 'EDİT ADDRESS',
      creditCard: 'CREDIT CARD / DEBIT CARD',
      creditNumber: 'CARD NUMBER',
      creditName: 'NAME AND SURNAME ON THE CARD',
      month: 'MONTH',
      year: 'YEAR',
      cvc: 'CVC',
    },
  },
};
